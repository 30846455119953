import { get, post } from 'utility/http';

export const getCountriesList = async () => {
  try {
    const response = await get('/bill/billing_countries');
    return response;
  } catch (error: any) {
    return Promise.reject(error.response ? error.response : error);
  }
};

export const getProvincesList = async () => {
  try {
    const response = await get('/bill/billing_province');
    return response;
  } catch (error: any) {
    return Promise.reject(error.response ? error.response : error);
  }
};

export const fetchGoogleSearch = async (body: {
  text: string;
  id?: any;
  sessionToken: string;
}): Promise<any> => {
  try {
    const response = await post(
      '/local/address_autocomplete',
      body?.id
        ? {
            id: body.id,
            text: body.text,
            status: 'confirmed',
            sessionToken: body.sessionToken
          }
        : {
            text: body.text,
            status: 'request',
            sessionToken: body.sessionToken
          }
    );
    return response;
  } catch (error: any) {
    return Promise.reject(error.response ? error.response : error);
  }
};

export const fetchPhoneCodeList = async (): Promise<any> => {
  try {
    const response = await get('/pa/phonenumbers/prefixes');
    return response;
  } catch (error: any) {
    return Promise.reject(error.response ? error.response : error);
  }
};
