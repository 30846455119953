import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { openToastify } from 'components/toast/OpenToast';
import { billingSliceInterface } from 'models/billingInterface';
import { postInvoiceData } from 'services/stepsAPI';
import {
  fetchGoogleSearch,
  fetchPhoneCodeList,
  getCountriesList,
  getProvincesList
} from 'services/utilityAPI';

const initialState: billingSliceInterface = {
  countries: {
    data: null,
    status: 'idle'
  },
  provinces: {
    data: null,
    status: 'idle'
  },
  invoice: {
    data: null,
    status: 'idle'
  },
  isBilling: {
    data: false,
    status: 'idle'
  },
  googleData: {
    data: null,
    status: 'idle'
  },
  googleDataPost: {
    data: null,
    status: 'idle'
  },
  prefixesList: {
    data: [],
    status: 'idle'
  }
};

export const getCountriesState = createAsyncThunk('billing/getCountriesList', async () => {
  try {
    const response: any = await getCountriesList();
    return response.data;
  } catch (error: any) {
    openToastify('danger', 'something_missing', error?.status);
    return Promise.reject(error.data ? error.data : error);
  }
});

export const getProvincesState = createAsyncThunk('billing/getProvincesList', async () => {
  try {
    const response: any = await getProvincesList();
    return response.data;
  } catch (error: any) {
    openToastify('danger', 'something_missing', error?.status);
    return Promise.reject(error.data ? error.data : error);
  }
});

export const postInvoiceDataState = createAsyncThunk(
  'billing/postInvoiceData',
  async (body: any) => {
    try {
      const response: any = await postInvoiceData(body);
      openToastify('success', 'success');
      return response.data;
    } catch (error: any) {
      if (error?.data?.msg) openToastify('danger', error?.data?.msg, error?.status);
      return Promise.reject(error.data ? error.data : error);
    }
  }
);

export const getGoogleSearch = createAsyncThunk(
  'billing/getGoogleSearch',
  async (body: { text: string; sessionToken: string }) => {
    try {
      const response: any = await fetchGoogleSearch(body);
      return response.data;
    } catch (error: any) {
      if (error?.data?.msg) openToastify('danger', error?.data?.msg, error?.status);
      return Promise.reject(error.data ? error.data : error);
    }
  }
);

export const postGoogleSearch = createAsyncThunk(
  'billing/postGoogleSearch',
  async (body: { id: any; text: string; sessionToken: string }) => {
    try {
      const response: any = await fetchGoogleSearch(body);
      return response.data;
    } catch (error: any) {
      if (error?.data?.msg) openToastify('danger', error?.data?.msg, error?.status);
      return Promise.reject(error.data ? error.data : error);
    }
  }
);

export const getPhoneCodeList = createAsyncThunk('billing/getPhoneCodeList', async () => {
  try {
    const response: any = await fetchPhoneCodeList();
    return response.data;
  } catch (error: any) {
    if (error?.data?.msg) openToastify('danger', error?.data?.msg, error?.status);
    return Promise.reject(error.data ? error.data : error);
  }
});

export const billingSlice = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    setIsBilling: (state, action) => {
      state.isBilling.data = action.payload;
    },
    resetCountries: (state) => {
      state.countries.data = null;
    },
    resetProvinces: (state) => {
      state.provinces.data = null;
    },
    resetInvoice: (state) => {
      state.invoice.data = null;
      state.invoice.status = 'idle';
    },
    resetIsBilling: (state) => {
      state.isBilling.data = false;
    },
    resetPrefixesList: (state) => {
      state.prefixesList.data = [];
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getCountriesState.pending, (state) => {
        state.countries.status = 'loading';
      })
      .addCase(getCountriesState.fulfilled, (state, action) => {
        state.countries.status = 'success';
        state.countries.data = action.payload;
      })
      .addCase(getCountriesState.rejected, (state) => {
        state.countries.status = 'rejected';
      })
      .addCase(getProvincesState.pending, (state) => {
        state.provinces.status = 'loading';
      })
      .addCase(getProvincesState.fulfilled, (state, action) => {
        state.provinces.status = 'success';
        state.provinces.data = action.payload;
      })
      .addCase(getProvincesState.rejected, (state) => {
        state.provinces.status = 'rejected';
      })
      .addCase(postInvoiceDataState.pending, (state) => {
        state.invoice.status = 'loading';
      })
      .addCase(postInvoiceDataState.fulfilled, (state, action) => {
        state.invoice.status = 'success';
        state.invoice.data = action.payload;
      })
      .addCase(postInvoiceDataState.rejected, (state) => {
        state.invoice.status = 'rejected';
      })
      .addCase(postGoogleSearch.pending, (state) => {
        state.googleDataPost.status = 'loading';
      })
      .addCase(postGoogleSearch.fulfilled, (state, action) => {
        state.googleDataPost.status = 'success';
        state.googleDataPost.data = action.payload;
      })
      .addCase(postGoogleSearch.rejected, (state) => {
        state.googleDataPost.status = 'rejected';
      })
      .addCase(getGoogleSearch.pending, (state) => {
        state.googleData.status = 'loading';
      })
      .addCase(getGoogleSearch.rejected, (state) => {
        state.googleData.status = 'rejected';
      })
      .addCase(getGoogleSearch.fulfilled, (state, action) => {
        state.googleData.status = 'success';
        state.googleData.data = action.payload;
      })
      .addCase(getPhoneCodeList.pending, (state) => {
        state.prefixesList.status = 'loading';
      })
      .addCase(getPhoneCodeList.rejected, (state) => {
        state.prefixesList.status = 'rejected';
      })
      .addCase(getPhoneCodeList.fulfilled, (state, action) => {
        state.prefixesList.status = 'success';
        state.prefixesList.data = action.payload;
      });
  }
});

export const {
  setIsBilling,
  resetCountries,
  resetProvinces,
  resetInvoice,
  resetIsBilling,
  resetPrefixesList
} = billingSlice.actions;
export const countriesState = (state: RootState) => state.billing.countries;
export const provincesState = (state: RootState) => state.billing.provinces;
export const invoiceState = (state: RootState) => state.billing.invoice;
export const isBillingState = (state: RootState) => state.billing.isBilling;
export const googleDataState = (state: RootState) => state.billing.googleData;
export const googleDataPostState = (state: RootState) => state.billing.googleDataPost;
export const prefixesListState = (state: RootState) => state.billing.prefixesList;

export default billingSlice.reducer;
